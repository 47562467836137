/** @jsx jsx */
import { jsx } from "theme-ui"

import About from "../components/About"
import Certifications from "../components/Certifications"
import Contact from "../components/Contact"
import Hero from "../components/Hero"

const Index = () => {
  return (
    <div>
      <Hero />
      <div sx={{ marginBottom: "100px" }}>
        <About />
      </div>
      <Certifications />
      <Contact />
    </div>
  )
}

export default Index
