/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
// eslint-disable-next-line no-unused-vars
import React from "react"
import { jsx } from "theme-ui"

import Markdown from "../Markdown"

const Hero = () => {
  const {
    allDatoCmsContent: { nodes },
  } = useStaticQuery(graphql`
    {
      allDatoCmsContent {
        nodes {
          title
          text
          image {
            url
            alt
          }
          menuItem {
            slug
          }
        }
      }
    }
  `)

  const { title, text, image, menuItem } = nodes[0]

  return (
    <section
      id={menuItem.slug}
      sx={{
        display: "flex",
        flexDirection: ["column", "row"],
        padding: "0 20px",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <article sx={{ flex: 1, marginTop: 8 }}>
        <h1
          sx={{
            color: "primary",
            fontSize: 6,
            fontFamily: "heading",
            fontWeight: "heading",
            maxWidth: "550px",
            margin: 0,
          }}
        >
          {title}
        </h1>
        <Markdown md={text} />
      </article>
      <div sx={{ flex: 1, paddingLeft: [null, 5] }}>
        <img
          alt={image.alt ? image.alt : ""}
          src={image.url}
          sx={{
            marginTop: [null, -8],
            height: "auto",
            maxWidth: "100%",
          }}
        />
      </div>
    </section>
  )
}

export default Hero
