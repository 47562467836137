/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
// eslint-disable-next-line no-unused-vars
import React from "react"
import { jsx } from "theme-ui"

import Puff from "../Puff"

const Certifications = () => {
  const {
    allDatoCmsPuff: { nodes },
  } = useStaticQuery(graphql`
    {
      allDatoCmsPuff {
        nodes {
          puffContents {
            title
            text
            puffLinkTitle
            puffLinkExternal
            puffImage {
              url
              alt
            }
            parallaxImage {
              url
            }
            mobileImage {
              url
            }
            id
          }
          menuItem {
            slug
          }
        }
      }
    }
  `)

  const { puffContents, menuItem } = nodes[0]

  return (
    <section id={menuItem.slug}>
      {puffContents.map((item, index) => (
        <Puff key={item.id} {...item} mirror={index % 2 === 1} />
      ))}
    </section>
  )
}

export default Certifications
