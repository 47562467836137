/** @jsx jsx */
import { bool, object, string } from "prop-types"
// eslint-disable-next-line no-unused-vars
import React from "react"
import { jsx } from "theme-ui"

import Content from "./Content"

const Puff = ({
  title,
  text,
  puffLinkTitle,
  puffLinkExternal,
  puffImage,
  parallaxImage,
  mobileImage,
  mirror,
}) => {
  return (
    <>
      <Content
        mirror={mirror}
        parallaxImage={parallaxImage}
        puffImage={puffImage}
        puffLinkExternal={puffLinkExternal}
        puffLinkTitle={puffLinkTitle}
        text={text}
        title={title}
      />
      <div
        sx={{
          backgroundImage: [
            `url(${mobileImage ? mobileImage.url : parallaxImage.url})`,
            `url(${parallaxImage.url})`,
          ],
          width: "100%",
          minHeight: "400px",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",

          "@media screen and (min-width: 820px)": {
            backgroundAttachment: "fixed",
          },
        }}
      />
    </>
  )
}

Puff.propTypes = {
  mirror: bool.isRequired,
  mobileImage: object.isRequired,
  parallaxImage: object.isRequired,
  puffImage: object.isRequired,
  puffLinkExternal: string.isRequired,
  puffLinkTitle: string.isRequired,
  text: string.isRequired,
  title: string.isRequired,
}

export default Puff
