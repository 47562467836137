/** @jsx jsx */

import { Link } from "gatsby"
import MarkdownJsx from "markdown-to-jsx"
import { node, string } from "prop-types"
import { jsx, Styled } from "theme-ui"

const MarkdownLink = ({ children, href }) => (
  <Link
    aria-label={href}
    sx={{
      color: "primary",
      fontWeight: "400",
      fontFamily: "heading",
      fontSize: [2, 3],
      lineHeight: "body",
    }}
    to={href}
  >
    {children}
  </Link>
)

MarkdownLink.propTypes = {
  children: node.isRequired,
  href: string.isRequired,
}

const MarkdownP = ({ children }) => (
  <Styled.p
    sx={{
      margin: "20px 0",
      color: "black",
    }}
  >
    {children}
  </Styled.p>
)

MarkdownP.propTypes = {
  children: node.isRequired,
}

const Strong = ({ children }) => (
  <strong sx={{ fontWeight: "700" }}>{children}</strong>
)

Strong.propTypes = {
  children: node.isRequired,
}

const Markdown = ({ md }) => (
  <div
    sx={{
      "h1, h2, h3": {
        marginTop: "40px",
      },
      h4: {
        marginTop: "20px",
      },
      "p, li, i span, code, blockquote": {
        fontSize: 2,
        lineHeight: "body",
        fontWeight: "body",
      },
      fontFamily: "body",
      "*": {
        maxWidth: "100%",
      },
    }}
  >
    <MarkdownJsx
      options={{
        overrides: {
          a: MarkdownLink,
          p: MarkdownP,
          span: MarkdownP,
          strong: Strong,
        },
      }}
    >
      {md}
    </MarkdownJsx>
  </div>
)

Markdown.propTypes = {
  md: string.isRequired,
}

export default Markdown
