/** @jsx jsx */
import { bool, object, string } from "prop-types"
// eslint-disable-next-line no-unused-vars
import React from "react"
import { BiLinkExternal } from "react-icons/bi"
import { jsx, Styled } from "theme-ui"

const Content = ({
  title,
  text,
  puffLinkTitle,
  puffLinkExternal,
  puffImage,
  mirror,
}) => {
  return (
    <div
      sx={{
        backgroundColor: mirror ? "secondary" : "primary",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0 20px",
          maxWidth: "1200px",
          margin: "0 auto",
          paddingBottom: 8,

          "@media screen and (min-width: 540px)": {
            flexDirection: mirror ? "row-reverse" : "row",
          },
        }}
      >
        <div
          sx={{
            width: "calc(100% / 3)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            border: `1px solid ${mirror ? "#abd1a8" : "#187cbc"}`,
            marginTop: -8,
            alignSelf: "flex-start",

            "@media screen and (max-width: 540px)": {
              marginLeft: mirror ? "auto" : "",
            },

            "::after": {
              content: '""',
              display: "block",
              paddingBottom: "100%",
            },
          }}
        >
          <img
            alt={puffImage.alt ? puffImage.alt : ""}
            src={puffImage.url}
            sx={{
              width: ["70px", "120px"],
              maxWidth: "100%",
              "@media screen and (min-width: 990px)": {
                width: "150px",
              },
            }}
          />
        </div>

        <article
          sx={{
            width: "100%",
            paddingTop: 7,

            "@media screen and (min-width: 540px)": {
              paddingTop: 8,
              paddingLeft: mirror ? "" : 8,
              paddingRight: mirror ? 8 : "",
              width: "calc((100% / 3) * 2)",
            },
          }}
        >
          <h2
            sx={{
              color: "white",
              fontSize: ["36px", 6],
              fontFamily: "heading",
              fontWeight: "heading",
              lineHeight: "heading",
              marginBottom: 4,
              marginTop: 0,
            }}
          >
            {title}
          </h2>
          <Styled.p sx={{ marginBottom: 5 }}>{text}</Styled.p>
          <a
            href={puffLinkExternal}
            sx={{
              position: "relative",
              padding: "15px 0",
              paddingRight: 5,
              color: "white",
              fontFamily: "body",
              textDecoration: "none",

              "::before": {
                content: '""',
                position: "absolute",
                bottom: "10px",
                left: 0,
                right: 0,
                borderWidth: "1px",
                borderColor: "inherit",
                borderStyle: "solid",
              },
            }}
          >
            {puffLinkTitle}
            <div
              sx={{
                position: "absolute",
                right: 0,
                top: "16px",
                fontWeight: "body",
                fontSize: 1,
                paddingTop: "1px",
              }}
            >
              <BiLinkExternal />
            </div>
          </a>
        </article>
      </div>
    </div>
  )
}

Content.propTypes = {
  mirror: bool.isRequired,
  puffImage: object.isRequired,
  puffLinkExternal: string.isRequired,
  puffLinkTitle: string.isRequired,
  text: string.isRequired,
  title: string.isRequired,
}

export default Content
