/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
// eslint-disable-next-line no-unused-vars
import React from "react"
import { jsx } from "theme-ui"

const Contact = () => {
  const {
    allDatoCmsContactContent: { nodes },
  } = useStaticQuery(graphql`
    {
      allDatoCmsContactContent {
        nodes {
          googleMapsIframe
          contactTitle
          contactLinkTitle
          contactLinkExternal
          menuItem {
            slug
          }
        }
      }
    }
  `)

  const {
    googleMapsIframe,
    contactTitle,
    contactLinkTitle,
    contactLinkExternal,
    menuItem,
  } = nodes[0]

  return (
    <section id={menuItem.slug}>
      <div
        sx={{
          padding: "0 20px",
          maxWidth: "1200px",
          margin: "50px auto",
        }}
      >
        <h2
          sx={{
            fontSize: 6,
            color: "primary",
            fontFamily: "heading",
            fontWeight: "heading",
            lineHeight: "heading",
            marginTop: 0,
            marginBottom: 8,
          }}
        >
          {contactTitle}
        </h2>

        <div sx={{ textAlign: "center" }}>
          <a
            href={contactLinkExternal}
            sx={{
              textDecoration: "none",
              fontSize: 3,
              color: "primary",
              fontFamily: "body",
              fontWeight: "body",
            }}
          >
            {contactLinkTitle}
          </a>
        </div>
      </div>

      <div
        dangerouslySetInnerHTML={{ __html: googleMapsIframe }}
        sx={{ iframe: { width: "100%", height: "400px" } }}
      />
    </section>
  )
}

export default Contact
