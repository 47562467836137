/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
// eslint-disable-next-line no-unused-vars
import React from "react"
import { jsx } from "theme-ui"

const Hero = () => {
  const {
    allDatoCmsHeroContent: { nodes },
  } = useStaticQuery(graphql`
    {
      allDatoCmsHeroContent {
        nodes {
          title
          titleColor {
            hex
          }
          imageDesktop {
            url
          }
          imageMobile {
            url
          }
          menuItem {
            slug
          }
        }
      }
    }
  `)

  const { imageDesktop, imageMobile, title, titleColor, menuItem } = nodes[0]

  return (
    <header
      id={menuItem.slug}
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundSize: "cover",
        backgroundPosition: "center, center",
        backgroundRepeat: "no-repeat",
        backgroundImage: [
          imageMobile ? `url(${imageMobile.url})` : `url(${imageDesktop.url})`,
          `url(${imageDesktop.url})`,
        ],
      }}
    >
      <h1
        sx={{
          color: titleColor.hex,
          fontSize: [5, 7],
          fontFamily: "heading",
          fontWeight: "heading",
          textAlign: "center",
          maxWidth: "550px",
          margin: 0,
        }}
      >
        {title}
      </h1>
    </header>
  )
}

export default Hero
